import {javascript} from "@api/index";
import {useHydration} from "@stores/utils";
import {defineStore} from "pinia";
import {computed, ref} from "vue";

export type UserStoreType = ReturnType<typeof useUserStore>
export const allUserStores = ref<Array<UserStoreType>>([])

type UserDataType = Backend.Models.User & {
  custom_attributes: Backend.Models.CustomAttribute[],
  custom_operations: Backend.Models.CustomOperation[]
}

export const createUserStore = (uniqueId: string | number) => {
  const userStore = defineStore('user-store-' + String(uniqueId), () => {
    const hydration = useHydration<UserDataType>(javascript.loggedUser)
    const hydrateById = (id: number, fields: Array<keyof UserDataType> = []) => { return hydration.hydrate({  id }, fields) }
    allUserStores.value.push(userStore as unknown as UserStoreType)

    const attributeLibrary = hydration.hydratedComputed('custom_attributes', (models) => {
      return models.map((model) => {
        // Fix yes_no questions
        if (model.question_type === 'yes_no'){
          model.question_options.options = [
            { label: model.question_options.checkedLabel, value: model.question_options.checkedValue},
            { label: model.question_options.uncheckedLabel, value: model.question_options.uncheckedValue}
          ] as Backend.Questionnaire.ISelectOption[]

          delete model.question_options.checkedLabel
          delete model.question_options.uncheckedLabel
          delete model.question_options.checkedValue
          delete model.question_options.uncheckedValue
        }

        const question = {
          att: model.name,
          desc: model.question_text,
          type: model.question_type,
          opts: {
            placeholder: model.question_hint,
            required: model.question_required,
            ...model.question_options
          }
        } as unknown as Backend.Questionnaire.IQuestion

        return window.AvvParser.normalize(question) as Backend.Questionnaire.IQuestion & { att: string }
      })
    })

    const attributeLibraryByAttribute = computed(() => attributeLibrary.value.reduce<Record<string, Backend.Questionnaire.IQuestion>>((memo, attribute) => {
      memo[attribute.att] = attribute
      return memo
    }, {}))

    const operationLibrary = hydration.hydratedComputed('custom_operations', (models) => {
      return models.map((model) => Object.assign({}, model, { text: model.text ? Ast.stringify(model.text) : model.text }))
    })

    return {
      ...hydration,
      hydrateById,
      id: hydration.hydratedComputed('id'),
      displayName: hydration.hydratedComputed('display_name'),
      attributeLibrary,
      attributeLibraryByAttribute,
      operationLibrary
    }
  })
  return userStore
}

export const useUserStore = createUserStore('current')
